.controls {
  color: white;
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 0 auto;
  margin-top: 2rem;
  display: none;
}

.middle {
  display: flex;
  margin: 2rem 0;
}

.middle > div:nth-child(1) {
  padding-right: 8rem;
}

.top,
.bottom {
  display: flex;
  justify-content: center;
}

.top:hover,
.bottom:hover,
.middle > div:hover {
  color: rgba(255, 255, 255, 0.7);
}

/* .icon {
  font-size: large;
} */

.use_arrow_info {
  text-align: center;
  color: white;
}

@media screen and (max-width: 768px) {
  .use_arrow_info {
    display: none;
  }
  .controls {
    display: flex;
  }
}

.root {
  background: linear-gradient(
    to bottom,
    rgba(9, 9, 9, 1) 5%,
    rgba(10, 5, 5, 0.55),
    rgba(10, 5, 5, 0.8) 90%
  );
  width: 100%;
  min-height: 100vh;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.root::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url("../assets/grid.svg") repeat,
    linear-gradient(#6f7074, #363838);
  background-size: 350px;
  z-index: -2;
}

.main {
  padding: 5rem 0 3rem 0;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.grid {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-auto-flow: row;
  row-gap: 40px;
  column-gap: 20px;
  place-items: center;
  padding: 0;
}

.card {
  background: #1d1d1d;
  width: 20em;
  height: 12rem;
  border-radius: 5px;
  position: relative;
  box-shadow: 0 0px 0px 0 #b0bec5;
  display: flex;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.08);
}

.card_image {
  margin-left: -2.5rem;
  padding: 1rem;
}

.card_image img {
  width: 12rem;
  height: 8rem;
  /* box-shadow: 3px 3px 0px 0 rgba(0, 0, 0, 0.2); */
  filter: drop-shadow(30px 10px 4px #00000021);
  display: block;
  object-fit: contain;
  border-radius: 6px;
  padding: 0.4rem;
}

.card_details {
  margin: 0;
  flex-grow: 1;
  padding: 1rem 1rem 1rem 0;
  max-width: 55%;
}

.card_details h2 {
  font-size: 1.2rem;
  line-height: 2rem;
  letter-spacing: 1px;
  color: #ffff;
  text-align: center;
  font-weight: bold;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
}

.tagline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

@media screen and (max-width: 1040px) {
  .card {
    width: 16rem;
  }
}

@media screen and (max-width: 992px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    width: 20rem;
  }
  .main {
    padding-left: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .main {
    padding: 1rem;
  }

  .card {
    width: 100%;
    flex-direction: column;
    height: auto;
    margin: 0;
    margin-bottom: 30px;
  }

  .gridItem {
    width: 100%;
    padding: 30px 2rem 0 2rem;
  }

  .card_image {
    margin-left: 0;
    transform: translateY(-40px);
  }

  .card_details {
    transform: translateY(-30px);
  }
}

.event-card-rules {
  overflow-x: hidden;
  overflow-y: visible;
  height: 50vh;
  width: 100%;
}

.event-card-rules::-webkit-scrollbar {
  width: 0.1em !important;
  border-radius: 10px !important;
}

.event-card-rules::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(0, 0, 0) !important;
  border-radius: 10px !important;
  background-color: #94949400 !important;
}

.event-card-rules::-webkit-scrollbar-thumb {
  background-color: rgba(117, 117, 117, 0.767) !important;
  border-radius: 10px !important;
}

.title {
  font-size: 1rem;
  color: #fff;
  background-color: rgba(200, 200, 200, 0.068);
  /* margin-bottom: 1%; */
  border-radius: 5px;
  padding: 1%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  text-align: left;
}

.title ul li {
  list-style-type: none;
  margin-left: 1em;
  line-height: 1.5em;
}

.title ul li::before {
  content: "\0BB";
  font-size: 1.7em;
  margin-right: 5px;
  color: red;
}

.event-card-rules .container {
  width: 100%;
}

@media only screen and (max-width: 1350px) {
  .title1 {
    font-size: 1.6rem;
  }
  .title {
    font-size: 1.2rem;
  }
  /* .event-card-rules {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto !important;
  } */
}

@media only screen and (max-width: 1100px) {
  .title1 {
    font-size: 1rem;
    padding: 0.5em;
  }
  .title {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 992px) {
  .title1 {
    font-size: 0.7em;
  }
  .event-card-rules {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1.5em;
    height: auto !important;
  }
}

@media only screen and (max-width: 600px) {
  .title {
    padding: 0.5rem 1rem;
  }
}

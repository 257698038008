.message404 {
  position: absolute;
  left: 400px;
  top: 400px;
  transform: translateY(-50%);
  font-family: "Roboto Mono", monospace;
  color: #363e49;
}
.message404 h2 {
  color: #ffffff;
  font-weight: bold;
  font-size: 8rem;
  margin: 0 0 8px;
}
.message404 h1 {
  font-size: 4rem;
  font-family: "Roboto Mono", monospace;
  font-weight: 300;
  color: rgb(255, 0, 0);
  margin: 0 0 16px;
}
.img404 {
  margin-top: 70px;
  position: relative;
  width: 185px;
  height: 130px;
  left: 60%;
  top: 60%;
  /* transform: translate(-20%, 20%) rotate(0deg) scale(1); */
  animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  -webkit-animation: spin 3s cubic-bezier(0.17, 0.49, 0.96, 0.76) infinite;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}
@keyframes spin {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(40px);
  }
}
@-webkit-keyframes spin {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(40px);
  }
}
.img404 img {
  width: 30rem;
  height: 30rem;
}
@media (max-width: 1600px) {
  .message404 {
    left: 200px;
    top: 300px;
  }
  .img404 img {
    width: 25rem;
    height: 25rem;
  }
}

@media (max-width: 1300px) {
  .message404 h2 {
    font-size: 5rem;
  }
  .message404 h1 {
    font-size: 2rem;
  }

  .img404 {
    top: 100px;
  }

  .img404 img {
    width: 20rem;
    height: 20rem;
  }
}
@media (max-width: 800px) {
  .message404 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    overflow: hidden;
    transform: translate(-50%, -50%);
  }

  .message404 h2 {
    font-size: 5rem;
  }
  .message404 h1 {
    font-size: 1.5rem;
  }

  .img404 {
    display: none;
  }
}

.neonBtn404 {
  color: white;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-size: 2em;
  padding: 0.25em 2.25em;
  border-radius: 0.25rem;
  text-shadow: 0 0 0.125em rgba(255, 0, 0, 0.541), 0 0 0.125em currentColor;
  position: relative;
  border: 1px solid red;
}

body {
  font-family: "Poppins", sans-serif !important;
  background-color: var(--secondary-black);
}

/*Model CSS*/
/* App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.model-container {
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.interaction-elements {
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
  max-width: 800px;
}

.element {
  padding: 15px 25px;
  background-color: #f0f0f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.element:hover {
  background-color: #e0e0e0;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.left-element {
  background-color: #ffcccc;
}

.center-element {
  background-color: #ccffcc;
}

.right-element {
  background-color: #ccccff;
}

/* Modal */

/* Custom Scrollbar */

*::-webkit-scrollbar {
  width: 0.5em !important;
  height: 5px;
  border-radius: 10px !important;
}

*::-webkit-scrollbar-track {
  background-color: #070707b2 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(194, 40, 40, 0.767) !important;
  border-radius: 10px !important;
}

:root {
  --primary-black: #090909;
  --secondary-black: #151515;
  --red: #d00b02;
  --red-secondary: #7f0600;
}

.nav-btn {
  padding: 0.2rem 0.8rem;
  margin: 0 0.8rem;
  transition: all 0.3s;
}

.nav-btn:not(.active):hover {
  transform: scale(1.05);
  color: var(--red);
}

.nav-btn.active {
  border-bottom: 2px solid var(--red);
}

.nav-menu-sm {
  height: calc(100vh - 65px);
  top: 65px;
}

.nav-menu-btn-sm,
.nav-menu-sm {
  display: none;
}

.nav-menu-sm-login-btn {
  animation: zoomIn 1.2s;
}

.nav-menu-sm > div::-webkit-scrollbar {
  display: none;
}

.game-icon-sm {
  display: none;
}

@media screen and (max-width: 992px) {
  .nav-menu-lg {
    display: none;
  }
  .nav-menu-btn-sm,
  .nav-menu-sm {
    display: flex;
  }
  .game-icon-lg {
    display: none;
  }
  .game-icon-sm {
    display: block;
  }
}

/* swal modal */

.swal-modal {
  background: linear-gradient(
    to right,
    rgba(45, 45, 45, 0.9),
    rgba(71, 71, 71, 0.9)
  );
}

.swal-title,
.swal-text {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

.swal-button {
  background-color: rgba(208, 11, 2, 0.5);
  border: 1px solid rgb(163, 9, 1);
  text-shadow: none;
  box-shadow: none;
  outline: none;
  filter: drop-shadow(0 0 0.3rem var(--red));
}

.swal-button:hover {
  background-color: rgba(208, 11, 2, 0.5);
  border: 1px solid rgb(163, 9, 1);
}

.swal-button:not([disabled]):hover {
  background-color: rgba(208, 11, 2, 0.5);
}

.Burger {
  /* position: absolute;
  top: 15%;
  left: 6%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.Burger:focus {
  outline: none;
}

.Burger div {
  width: 2rem;
  height: 0.25rem;
  background: white !important;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.Burger div:nth-child(2) {
  background: red !important;
}

.gallery {
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row;
  /* grid-auto-rows: 10rem; */
  grid-template-areas:
    "img11 img11 img53 img19"
    "img11 img11 img22 img36"
    "img41 img25 img49 img20"
    "img50 img27 img27 img2"
    "img12 img27 img27 img14"
    "img9 img13 img6 img10"
    "img47 img31 img3 img17"
    "img51 img28 img52 img24"
    "img51 img54 img42 img5"
    "img34 img4 img18 img18"
    "img39 img38 img18 img18";
}

@media screen and (max-width: 768px) {
  .gallery {
    /* 1,11,24 */
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "img11 img11"
      "img11 img11"
      "img53 img19"
      "img22 img36"
      "img41 img25"
      "img49 img20"
      "img50 img2"
      "img27 img27"
      "img27 img27"
      "img12 img14"
      "img9 img13"
      "img6 img10"
      "img47 img31"
      "img3 img17"
      "img51 img28"
      "img51 img54"
      "img52 img24"
      "img42 img5"
      "img18 img18"
      "img18 img18"
      "img34 img4"
      "img39 img38";
  }
}

.gallery,
.gallery-container {
  transition: all 0.5s;
}

.gallery:hover .gallery-img {
  /* transition: all 0.5s; */
  filter: grayscale(1);
}

.gallery-container:hover .gallery-img {
  /* transition: all 0.5s; */
  filter: grayscale(0);
}

.gallery-img {
  transition: all 0.5s;
}

.gallery-img:hover {
  /* transition: all 0.5s; */
  transform: scale(1.1, 1.1);
}

.gallery-container:hover .gallery-caption {
  transition: all 0.5s;
  opacity: 1;
}

.gallery-caption {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

/* --------------------- react-image-modal overrides  -------------------- */

.__react_modal_image__header {
  padding: 0.5rem 1rem;
}

.__react_modal_image__icon_menu {
  padding-top: 1rem;
  padding-right: 1rem;
}

.__react_modal_image__icon_menu a {
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;
}

.__react_modal_image__icon_menu a:hover {
  transform: scale(1.4);
}

.__react_modal_image__caption {
  font-family: BlackHawk;
  font-size: 1.8rem !important;
  letter-spacing: 2px;
  background: linear-gradient(
    to right,
    #fff 10%,
    var(--red) 40%,
    #fff 65%,
    var(--red) 90%
  );
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

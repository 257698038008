@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .nav-right-btn {
    @apply py-1 px-6 rounded-lg border-2 border-[#a30901] transition-all;
  }
  .footer-icons {
    @apply px-1.5 py-1 m-1 relative hover:translate-y-[-3px] transition-all;
  }
  .clubs-heading {
    @apply text-white font-bold tracking-[15px] font-['Montserrat'] text-3xl min-w-[20vw] md:min-w-[10vw] md:h-[full] p-4 shadow-xl mx-[2px] ml-0 border border-[rgba(55,55,55,0.8)] bg-[rgba(30,30,33)] flex md:flex-col flex-row-reverse justify-evenly items-center border-l-0;
  }
}

/* .club-more-info-dialog-close-btn {
    @apply text-2xl text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full px-2 py-1 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700;
  } */

* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: BlackHawk;
  font-size: larger;
  font-weight: bolder;
  src: url(./styles/fonts/BLACKHAWK.ttf);
}

@font-face {
  font-family: BlackHawk_Italic;
  font-size: larger;
  font-weight: bolder;
  src: url(./styles/fonts/BLACKHAWK\ Italic.ttf);
}
@font-face {
  font-family: SKCuber;
  src: url(./assets/fonts/SK\ Cuber\ Expanded\ Trial\ 400.ttf) format(truetype);
  font-weight: normal;
  font-style: normal;
}
.font-black-hawk {
  font-family: BlackHawk;
}

.font-black-hawk-italic {
  font-family: BlackHawk_Italic;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.cube-container {
  perspective: 800px;
  /* filter: drop-shadow(0 100px 0.5rem rgb(88, 0, 0)); */
}

.photo-cube {
  transform-style: preserve-3d;
  transition: transform 2s ease-in-out;
}

.photo-cube:hover {
  transform: rotateY(-270deg);
}

.photo-cube .front {
  transform: translate3d(0, 0, 90px);
}

.photo-cube .left {
  transform: rotateY(-270deg) translate3d(110px, 0, 0);
  transform-origin: top right;
}

.photo-cube .right {
  transform: translateZ(-110px) rotateY(180deg);
}

.photo-cube .back {
  transform: translateZ(-110px) rotateY(270deg);
  transform-origin: center left;
}

.photo-cube .bottom {
  transform: rotateX(90deg) translateZ(-130px);
}

/* @media screen and (max-width:1400px) {
  .team-container{
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
} */

@media screen and (max-width: 992px) {
  .team-container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 600px) {
  .team-container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

/* heading styles */

.team-heading h1 {
  /* position: absolute; */
  transform: translateX(-50%) translateY(-50%);
}

.team-heading h1:before,
.team-heading h1:after {
  content: "";
  padding: 0.5em 0.1em;
  position: absolute;
  left: 50%;
  width: 50vw;
  top: 50%;
  height: 15vh;
  display: block;
  /* border: 5px solid red; */
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
  opacity: 0.5;
  /* animation: 8s infinite linear tipsy; */
}

/* .team-heading h1:before {
  border-color: #7f0600 #6d231f rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  z-index: -1;
}

.team-heading h1:after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #7f0600 #7f0600;
  box-shadow: 10px 10px 10px rgba(18, 18, 18, 0.9);
} */

@media screen and(max-width) {
}

/* @keyframes tipsy {
  0%{
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  25%{
    transform: translateX(-50%) translateY(-50%) rotate(20deg);
  }

  50%{
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  75%{
    transform: translateX(-50%) translateY(-50%) rotate(-20deg);
  }

  100%{
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
} */

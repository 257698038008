.club-card-container {
  perspective: 1000px;
}

.club-card-inner {
  transition: transform 2s;
  transform-style: preserve-3d;
}

.club-card-front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.club-card-container:hover .club-card-inner {
  /* transition-delay: 0.5s; */
  transform: rotateY(180deg);
  pointer-events: none;
}

.club-card-container:hover .club-card-img {
  transform: translate3d(0, 0, 50px) perspective(100px) scale(0.7);
  transition: all 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  pointer-events: none;
}

.club-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  /* transform: translate3d(0, 0, -50px) perspective(100px); */
}

/* .club-card-container:hover .club-card-inner::before {
  content: "";
  background-color: aquamarine;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition-delay: 0.5s;
  transition: all 1s;
  transform: translateX(-100%);
  border-radius: 0.5rem;
}

.club-card-container:hover .club-card-inner::before{
  transition-delay: 1s;
  transform: translateX(0);
  border-radius: 0.125rem;
} */

.club-card-img {
  transform: translate3d(0, 0, 50px) perspective(100px) scale(1);
  transition: all 0.5s;
  transition-delay: 0.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

/* ----------- for aligning last two item center ------------ */
@media screen and (min-width: 1341px) {
  .second-last-item {
    grid-column: 2 / span 1;
  }
}

@media screen and (min-width: 992px) and (max-width: 1340px) {
  .last-item {
    grid-column: 1 / span 3;
  }
}

/* ---------------------------------------------------------- */

@media screen and (max-width: 1340px) {
  .club-section {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .club-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 672px) {
  .club-section {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 2rem;
  }
}

/* background */
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  background-color: #2d2d2d8a;
  border-radius: 0.5rem;
}

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  opacity: 0.5;
  filter: brightness(0.5);
}
.line::after {
  content: "";
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.471) 75%,
    rgb(255, 101, 101) 100%
  );
  -webkit-animation: drop 5s 0s infinite;
  animation: drop 5s 0s infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}
.line:nth-child(1) {
  margin-left: -35%;
}
.line:nth-child(1)::after {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.line:nth-child(3) {
  margin-left: 35%;
}
.line:nth-child(3)::after {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

@-webkit-keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}

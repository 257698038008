.hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin: 0 auto;
  font-family: sans-serif;
  list-style-type: none;
  overflow: hidden;
  font-family: "Roboto Mono", monospace !important;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  backface-visibility: hidden;
  will-change: transform;
  transition: all 0.5s;
}

.hex::after {
  content: "";
  display: block;
  padding-bottom: 86.602%;
  /* =  100 / tan(60) * 1.5 */
}

.hexIn {
  position: absolute;
  width: 96%;
  padding-bottom: 110.851%;
  /* =  width / sin(60) */
  margin: 2%;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  -ms-transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transform: rotate3d(0, 0, 1, -60deg) skewY(30deg);
  transition: all 0.5s;
}

.hexIn * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hexLink {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  -ms-transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
  transform: skewY(-30deg) rotate3d(0, 0, 1, 60deg);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 1201px) {
  /* <- 5-4  hexagons per row */
  /* .hexGrid {
      padding-bottom: 5.4%;
    } */

  /* .hex {
      width: 20%;
    }
    .hex:nth-child(9n + 6) {
      margin-left: 10%;
    } */
}

@media (max-width: 1200px) and (min-width: 901px) {
  /* <- 4-3  hexagons per row */
  /* .hexGrid {
      padding-bottom: 5.5%;
      font-size: 13px;
    } */

  /* .hex {
      width: 25%;
    }
    .hex:nth-child(7n + 5) {
      margin-left: 12.5%;
    } */
}

@media (max-width: 900px) and (min-width: 601px) {
  /* <- 3-2  hexagons per row */
  /* .hexGrid {
      padding-bottom: 7.4%;
      font-size: 14px;
    } */

  /* .hex {
      width: 33.333%;
    }
    .hex:nth-child(5n + 4) {
      margin-left: 16.666%;
    } */
}

@media (max-width: 600px) {
  /* <- 2-1  hexagons per row */
  /* .hexGrid {
      font-size: 12px;
    } */

  /* .hex {
      width: 50%;
    }
    .hex:nth-child(3n + 3) {
      margin-left: 25%;
    } */
}
